import React from 'react';
import Dashboard from './pages/Dashboard'; // 根据Dashboard组件的实际路径调整

function App() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default App;
